<template>
	<div class="wrapper">
        <div class="flex">
            <div style="width: 50%;" v-loading="loading">
                <el-table :data="list" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
                    <el-table-column prop="id" label="id"></el-table-column>
                    <el-table-column prop="member_head_img" label="头像">
                        <template slot-scope="scope">
                            <img :src="scope.row.member_head_img" alt="" style="width: 60px">
                        </template>
                    </el-table-column>
                    <el-table-column prop="member_name" label="昵称"></el-table-column>
                </el-table>
            </div>
            <div style="width: 55%;padding-left:4%" v-loading="loadingSeting">
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="150px">
                    <el-form-item label="其他设置：">
                        <span style="color:#ff4d4d">（保存后实时跟新）</span>
                    </el-form-item>
                    <el-form-item label="机器人休眠时间：" prop="sleep_time">
                        <el-input-number size="small" v-model="ruleForm.sleep_time" :min="1"></el-input-number>
                    </el-form-item>
                    <el-form-item label="点赞几率：" prop="vote_rate">
                        <el-select size="small" v-model="ruleForm.vote_rate" placeholder="请选择">
                            <el-option v-for="item in voteStep" :key="item" :label="item" :value="item"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="small" type="primary" @click="onSubmit('ruleForm')">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-pagination v-if="total > 0" style="padding-top: 15px;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total"></el-pagination>  
	</div>
</template>
<script>
    import {getVirtualUsers, getVirtualDetail, saveVirtualConfig} from '@/api/courseOrder.js'
	export default {
		name: "RobotLike",
		data() {
			return {
                loading: true,
                loadingSeting: true,
                // 列表
				total: 0,
				limit: 10,
				currentPage: 1,
                list: [],
                // 其他设置
                voteStep: [5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100],
                initialValue: {}, //初始值
                ruleForm: {
                    sleep_time: '',
                    vote_rate: ''
                },
                rules: {
					sleep_time: [
						{ required: true, message: '请输入机器人休眠时间', trigger: 'blur' }
                    ],
                    vote_rate: [
						{ required: true, message: '请选择点赞几率', trigger: 'change' }
                    ],
                },
			}
		},
        mounted() {
            this.getList()
            this.getInfo()
        },
		methods: {
            // 获取列表
			getList() {
				this.loading = true
				getVirtualUsers({
					limit: this.limit,
					page: this.currentPage,
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false
				}).catch((err) => {
					this.loading = false
					console.log(err)
				})
            },
            // 获取上次设置信息
            getInfo() {
                this.loadingSeting = true
                getVirtualDetail({
					id: 1
				}).then((res) => {
                    // 因为后台说这里获取得时候如果么有值就判断第一次保存不传id
                    this.initialValue = res.data
                    this.ruleForm.sleep_time = res.data.sleep_time || 1
                    this.ruleForm.vote_rate = res.data.vote_rate || ''
                    this.loadingSeting = false
				}).catch((err) => {
					this.loadingSeting = false
					console.log(err)
				})
            },
            // 开始生成
            onSubmit(formName) {
                this.$refs[formName].validate((valid) => {
					if (valid) {
						this.save()
					}
				});
            },
            save() {
                let params = { ...this.ruleForm }
                this.initialValue && Object.keys(this.initialValue).length ? params.id = 1 : ''
                saveVirtualConfig(params).then((res) => {
					this.$message({message: res.msg, type: 'success'});
				}).catch((err) => {
					console.log(err, 222)
				})
            },
            // 页码修改
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
            },
		}
	};
</script>
<style lang="less" scoped>
.flex {
    display: flex;
}
</style>
