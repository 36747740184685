import request from '@/util/request'

// 视频列表(视频)
export function videoList(params) {
  return request({
    url: '/admin/v1/videoList',
    method: 'GET',
    params,
  })
}

// 上传视频(视频)
export function saveVideo(data) {
    return request({
      url: '/admin/v1/saveVideo',
      method: 'POST',
      data,
    })
}


// 视频上下架(视频)
export function onOrOffVideo(data) {
    return request({
      url: '/admin/v1/onOrOffVideo',
      method: 'POST',
      data
    })
}

// 删除视频(视频)
export function delVideo(params) {
    return request({
      url: '/admin/v1/delVideo',
      method: 'DELETE',
      params,
    })
}

// 获取视频详情(视频)
export function videoInfo(params) {
  return request({
    url: '/admin/v1/videoInfo',
    method: 'GET',
    params,
  })
}

// 获取课程列表(课程)
export function courseList(params) {
  return request({
    url: '/admin/v1/courseList',
    method: 'GET',
    params,
  })
}

// 上传课程(课程)
export function saveCourse(data) {
  return request({
    url: '/admin/v1/saveCourse',
    method: 'POST',
    data,
  })
}

// 获取课程详情(课程)
export function courseInfo(params) {
  return request({
    url: '/admin/v1/course',
    method: 'GET',
    params,
  })
}

// 上下架课程(课程)
export function onOrOffCourse(data) {
  return request({
    url: '/admin/v1/onOrOffCourse',
    method: 'POST',
    data,
  })
}

// 删除课程(课程)
export function delCourse(params) {
  return request({
    url: '/admin/v1/delCourse',
    method: 'DELETE',
    params,
  })
}

// 订单列表
export function orderList(params) {
  return request({
    url: '/admin/v1/order/list',
    method: 'get',
    params,
  })
}

// 订单详情
export function orderDetail(params) {
  return request({
    url: '/admin/v1/order/detail',
    method: 'get',
    params,
  })
}

// 问答列表
export function questionList(params) {
  return request({
    url: '/admin/v1/courseAndOrder/questionList',
    method: 'get',
    params,
  })
}

// 删除问答
export function delQorA(params) {
  return request({
    url: '/admin/v1/courseAndOrder/delQorA',
    method: 'DELETE',
    params,
  })
}

// 订单延期
export function changeExTime(params) {
  return request({
    url: '/admin/v1/order/changeExTime',
    method: 'POST',
    params,
  })
}

// 增加虚拟点赞
export function autoSchoolVote(data) {
  return request({
    url: '/admin/v1/autoSchoolVote',
    method: 'POST',
    data,
  })
}

//  虚拟播放和点赞脚本参数配置
export function saveVirtualConfig(data) {
  return request({
    url: '/admin/v1/business/saveVirtualConfig',
    method: 'POST',
    data,
  })
}

//  获取虚拟访问点赞配置详情
export function getVirtualDetail(params) {
  return request({
    url: '/admin/v1/business/getVirtualDetail',
    method: 'GET',
    params,
  })
}

//  获取虚拟访问点赞人列表
export function getVirtualUsers(params) {
  return request({
    url: '/admin/v1/business/getVirtualUsers',
    method: 'GET',
    params,
  })
}